import React, {useEffect, useState} from "react";
import {SearchInput} from "../input/SearchInput";
import {Table} from "../table/table";
import {useNavigate} from "react-router-dom";
import SVGraphics from "../../assets/SVGraphics";
import {SelectAlphaIcon} from "../input/SelectAlphaIcon";
import Constants from "../../utils/constants";
import utils from "../../utils/utils";
import OrdersApi from "../../services/ApiServices/OrdersApi";
import moment from "moment";
import {useAppSelector} from "../../redux/hooks";
import {authenticationService} from "../../services/AuthinticationService";

export function Orders() {
    const navigate = useNavigate();
    const [isFirstRun, setIsFirstRun] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [filteredData, setFilteredData] = useState([]);
    const [searchInputs, setSearchInputs] = useState(Constants.orders_searchInputs);
    const [orderFiltersData, setOrderFiltersData] = useState(Constants.orders_FilterInputs);
    const [orders, setOrders] = useState(null);
    const [ordersFilter, setOrdersFilter] = useState({});
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        fetchData('priority', ordersFilter).then(dataResponse => {
            if (isFirstRun) {
                getFilterData().then(filterData => {
                    setData(dataResponse, isFirstRun, filterData)
                })
            } else {
                setData(dataResponse, isFirstRun)
            }
        })
    }, [ordersFilter])

    const getFilterData = async () => {
        return await OrdersApi.getOrdersStatusesAndTypes()
    }

    const fetchData = async (type, ordersFilter) => {
        let filterKeys = Object.keys(ordersFilter)
        let allOrders
        // if (type === 'db') {
        //     allOrders = await OrdersApi.getOrders(ordersFilter)
        // }
        if ((allOrders && allOrders.data['Complete'].length === 0 && filterKeys.length === 0) || type === 'priority') {
            allOrders = await OrdersApi.getOrdersFromPriority()
        }
        return allOrders
    }

    const setData = (dataResponse, isFirstRun, filterData) => {
        let orders = []
        let _filtersData = [...orderFiltersData]
        if (dataResponse && dataResponse.data) {
            orders = dataResponse.data['Complete']
            _filtersData[0]['value'] = dataResponse.data['totalOrders']
            _filtersData[1]['value'] = dataResponse.data['pendingPlan']
            _filtersData[2]['value'] = dataResponse.data['pendingApproval']
            _filtersData[3]['value'] = dataResponse.data['approvedOrders']
        }
        const _searchInputs = [...searchInputs]
        let statuses = []
        let types = []
        if (filterData && filterData.data) {
            filterData.data['statuses'].forEach(status => {
                if (status['ORDSTATUSDES'].toLowerCase() !== "canceled" && status['ORDSTATUSDES'].toLowerCase() !== "performed") {
                    statuses.push({label: status['ORDSTATUSDES'], value: status['ORDSTATUSDES']})
                }
            })
            filterData.data['orderTypes'].forEach(type => {
                types.push({label: utils.getOrderTypeLabel(type['TYPECODE']), value: type['TYPECODE']})
            })
        }
        if (isFirstRun) {
            _searchInputs[0]['data'] = statuses
            _searchInputs[1]['data'] = types
        }
        setSearchValue("")
        setOrders(orders)
        setFilteredData(orders)
        setOrderFiltersData(_filtersData)
        setSearchInputs(_searchInputs)
        setIsLoading(false)
        setIsFirstRun(false)
    }

    const refreshData = async () => {
        setIsLoading(true)
        let dataResponse = await fetchData('priority',{})
        let filterData = await getFilterData()
        setData(dataResponse, true, filterData)
    }

    const filterOrders = async (name, value) => {
        let filters = {...ordersFilter}
        if (!value && filters[name]) {
            delete filters[name]
        } else {
            filters[name] = value.value
        }
        if(filters['planStatus']){
            delete filters['planStatus']
        }
        setIsLoading(true)
        setOrdersFilter(filters)
    }

    const filterOrdersByPlanStatus = async (name, value) => {
        let valueToAdd = value?.value
        let filters = valueToAdd !== 'totalOrders' ? {planStatus: valueToAdd} : {}
        setIsLoading(true)
        setOrdersFilter(filters)
    }

    const columns = [
        {
            Header: 'Production Site',
            accessor: 'Site',
            headerClassName: 'max-w-[4rem]',
            Cell: (cell) => {
                return (<div>
                        {cell.row.original.Site ? cell.row.original.Site['SiteName'] : ''}
                    </div>
                )
            }
        },
        {
            Header: ' PO ID',
            accessor: 'OrderID', // accessor is the "key" in the data
            headerClassName: ' max-w-[3rem]',
            Cell: (cell) => {
                return (<div>
                        {cell.row.original.ORDNAME?.substr(cell.row.original.ORDNAME.length - 4)}
                    </div>
                )
            }
        },
        {
            Header: 'Order Status',
            accessor: 'ORDSTATUSDES',
            headerClassName: 'max-w-[4.5rem]',
        },

        {
            Header: 'Procedure Date',
            accessor: 'SIBD_TREATDAY',
            headerClassName: 'max-w-[5rem]',
            Cell: (cell) => {
                return (<div>
                        {cell.row.original.SIBD_TREATDAY ? moment(cell.row.original.SIBD_TREATDAY).format('MMM DD YY') : ''}
                    </div>
                )
            }
        },
        {
            Header: 'Procedure Time',
            accessor: 'SIBD_TREATTIME',
            headerClassName: 'max-w-[5rem]',
        },
        {
            Header: 'Pick up Date and Time',
            accessor: 'EDATE',
            headerClassName: ' w-[8rem]',
            Cell: (cell) => {
                let pickUpDate
                if (cell.row.original['EDATE']) {
                    pickUpDate = new Date(cell.row.original['EDATE'])
                    let pickUpTime = cell.row.original.SIBD_COLLECTTIME.split(":")

                    pickUpDate = new Date(pickUpDate.setHours(Number(pickUpTime[0]), Number(pickUpTime[1]), Number(pickUpTime[2])))
                }

                return (<div>
                        {cell.row.original.EDATE ? moment(pickUpDate).format('DD MMM YYYY h:mm A') : ''}
                    </div>
                )
            }
        },
        {
            Header: 'Target Activity Level',
            accessor: 'SBD_PREFACTIV',
            headerClassName: ' w-[8rem]',
        },

        {
            Header: 'Procedure Location',
            accessor: 'Y_5380_6_ESHB',
            headerClassName: 'max-w-[5rem]'
        },
        {
            Header: 'Clinical Site',
            accessor: 'CUSTDES',
            headerClassName: 'max-w-[8rem]',
        },
        {
            Header: 'Required Seeds',
            accessor: 'SBD_SEEDQTY',
            headerClassName: 'max-w-[4rem]',
        },
        {
            Header: 'Order Type',
            accessor: 'TYPECODE',
            headerClassName: 'max-w-[4rem]',
            Cell: (cell) => {
                let type = utils.getOrderTypeLabel(cell.row.original['TYPECODE'])
                if (cell.row.original['TYPECODE'] === '008') {
                    type = type + "-" + cell.row.original['ALPH_CHAMBERNUM']
                }
                return (
                    <div>{type}</div>
                )
            }
        },
        {
            Header: () => (
                <div
                    className={'w-[3rem] h-[3rem] bg-alphaTauButton rounded-[0.625rem] flex justify-center items-center cursor-pointer'}
                    onClick={() => refreshData()}
                >
                    <SVGraphics className={'w-[1.5rem]'} svgname={'refresh-white'}/>
                </div>
            ),
            accessor: 'calculator',
            disableSortBy: true,
            width: 40
        },
    ]

    const handleSearch = (event) => {
        let filterValue = event.target.value.toLowerCase()
        let filteredData = []
        for (let i = 0; i < orders.length; i++) {
            filteredData = orders.filter(user =>
                String(user['ORDNAME']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['ORDSTATUSDES']).toLowerCase().indexOf(filterValue) > -1 ||
                String(utils.getOrderTypeLabel(user['TYPECODE'])).toLowerCase().indexOf(filterValue) > -1
            )
        }
        setSearchValue(event.target.value)
        setFilteredData(filteredData)
    }

    const openCalculator = async (id) => {
        let user = authenticationService.currentUserValue
        let userRole = user.role[0]
        if (userRole === 'SystemAdmin' || userRole === 'ProductAdmin' || userRole === 'TeamLeader') {
            let orderID = id.original.OrderID
            if (id.original['TYPECODE'] !== '008') {
                navigate(`/orders/calculateOrder/${orderID}`)
            }
        }
    }

    return (

        <div className={Constants.mainScreenClass}>
            <div className={"w-width90"}>
                <div className={'flex justify-between lg:flex md:grid md:grid-cols-2 md:gap-4'}>
                    {
                        orderFiltersData.map(filter => {
                            return <div
                                key={filter.name}
                                onClick={() => filterOrdersByPlanStatus("planStatus", {value: filter.name})}
                                className={'w-[19%] lg:w-[19%] md:w-3/4 h-[7.063rem] bg-[#F2F5FA] rounded-[0.625rem] shadow-md flex items-center justify-center cursor-pointer'}
                            >
                                <SVGraphics className={'w-[4.375rem] '} svgname={filter.svgName}/>
                                <div className={'ml-[0.813rem]'}>
                                    <div
                                        className={'text-alphaTauButton font-Roboto font-bold leading-[2.625rem] text-[2.25rem] h-[2.625rem]'}>{filter.value}</div>
                                    <div
                                        className={'text-[#747879] font-Roboto leading-[1.125rem] text-[0.938rem]'}>{filter.label}</div>
                                </div>

                            </div>
                        })
                    }
                </div>

                <div className={'flex lg:flex-row md:flex-col mt-8 justify-between'}>
                    <div className={'w-[51%]c lg:w-[51%] md:w-[90%] h-[3.5rem]'}>
                        <SearchInput
                            value={searchValue}
                            onChange={(e) => handleSearch(e)}
                        />
                    </div>

                    <div className={"site-search-inputs ml-6 lg:mt-0 lg:ml-6 md:mt-4 md:ml-0"}>
                        {searchInputs.map(item => {
                            return <SelectAlphaIcon
                                key={item.name}
                                svgName={item.svgName}
                                data={item.data}
                                type={item.type}
                                value={ordersFilter[item.name]}
                                selected={ordersFilter[item.name]}
                                name={item.name}
                                label={item.label}
                                svgClass={'w-[1.5rem] h-[1.5rem]'}
                                onChange={(e) => filterOrders(item.name, e.target.value)}
                                // onChange={(e)=>onChangeFilter(e)}
                                hasLine={item.hasLine}
                                inputClassName={'Select-Icon'}
                            />
                        })}
                    </div>
                </div>

                <Table data={filteredData ? filteredData : []} columns={columns} isLoading={isLoading}
                       onClick={openCalculator}
                />
            </div>


        </div>
    )
}