import React, {useEffect, useState} from "react";
import SystemAdmin from '../../../services/ApiServices/SystemAdminApi';
import {Table} from '../../table/table';
import UsersOptions from '../../options/UsersOptions';
import {useNavigate} from 'react-router-dom';
import {SelectAlphaIcon} from '../../input/SelectAlphaIcon';
import SVGraphics from '../../../assets/SVGraphics';
import {Switch} from "@mui/material";
import {SearchInputButtonComponent} from "../../input/SearchInputButtonComponent";
import Constants from "../../../utils/constants";
import {AlertConfirm} from "../../modals/Confirm";
import {SlideInput} from "../../input/SlideInput";


export function Sites() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [countryStates, setCountryStates] = useState([]);
    const [siteFilter, setSiteFilter] = useState({filters: []});
    const [sites, setSites] = useState(null);
    const [searchInputs, setSearchInputs] = useState([
        {
            name: "Status", label: "Status", type: "Select",
            data: [{value: true, label: 'Active'}, {value: false, label: 'Disabled'}],
            hasLine: true, svgName: "toogleRight", disabled: false
        },
        {
            name: "Country",
            label: "Country",
            type: "Select",
            data: [],
            hasLine: true,
            svgName: "sitePlace",
            disabled: false
        },
        {
            name: "State",
            label: "State",
            type: "Select",
            data: [],
            hasLine: true,
            svgName: "sitePlace",
            disabled: true
        }]);

    useEffect(() => {
        setIsLoading(true)
        fetchData().then(p => {
            fetchDataGetSitesFilters().then(filters => {
                if (filters && filters.data) {
                    let siteFilters = filters.data
                    let _countries = []
                    filters.data.forEach(country => {
                        _countries.push({label: country.Country, value: country.Country})
                    })
                    searchInputs[1]['data'] = _countries
                    setCountryStates(siteFilters)
                    setSearchInputs(searchInputs)
                }
            })
            setData(p)


        })
    }, [])

    const fetchData = async () => {
        return await SystemAdmin.getAllSitesAdvanced(siteFilter)
    }

    const setData = (p) => {
        setSites(p.data)
        setFilteredData(p.data)
        setIsLoading(false)
    }

    const fetchDataGetSitesFilters = async () => { //get patient data from db
        return await SystemAdmin.getSitesCountry()
    }
    const handleSiteStatus = async (event, siteId,isActive) => {
        const checked = event.target.checked

        if (!checked && isActive) {
            let confirm = await AlertConfirm({
                proceedLabel: 'Ok',
                cancelLabel: 'Cancel',
                options: {hasSVG: true, svgName: "red-warning-circle"}
            }, 'Are you sure you want to disable this site','Disable Site',)

            if(confirm){
                await SystemAdmin.disableSite({siteID:siteId})
                fetchData().then(p=>setData(p))
                }

        }
    }
    const handleDefaultSite = async (event, siteId,isActive) => {
        //const checked = event.target.checked
        //if(checked){
            await SystemAdmin.setDefaultSite({siteID:siteId})
            fetchData().then(p=>setData(p))
        //}
    }
    const handleSearch = (event) => {
        let filterValue = event.target.value.toLowerCase()
        let filteredData = []
        for (let i = 0; i < sites.length; i++) {
            filteredData = sites.filter(user =>
                String(user['SiteName']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['Country']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['Street']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['Number']).toLowerCase().indexOf(filterValue) > -1 ||
                String(user['Type']).toLowerCase().indexOf(filterValue) > -1
            )
        }
        setFilteredData(filteredData)
    }

    const columns = [
        {
            Header: 'Site Name',
            accessor: 'SiteName', // accessor is the "key" in the data
            // sortType: 'string'
        },
        {
            Header: 'Country',
            accessor: 'Country',
            // sortType: 'string'
        },
        {
            Header: 'Street',
            accessor: 'Street',
            // sortType: 'string'
        },
        {
            Header: 'Number',
            accessor: 'Number',
            // sortType: 'string'
        },
        {
            Header: 'Production Admin',
            accessor: '',
            // sortType: 'string',
            Cell: (props) => {
                return (<UsersOptions options={props.row.original.ProductAdmin.Admins}/>)
                // <div>
                //
                //     {props.row.original.Users.length > 0 ?props.row.original.Users[0].PII.FirstName+" "+props.row.original.Users[0].PII.LastName:''}
                // </div>
            }
        },
        {
            Header: '№ of active chambers',
            accessor: 'ChamberCount',
            // sortType: 'string'
        },
        {
            Header: 'Type',
            accessor: 'Type',
            // sortType: 'string'
        },
        {
            Header: 'Active',
            accessor: 'IsActive',
            Cell: (props) => {
                return (
                    <SlideInput
                        leftLabel={""}
                        name={"IsActive"}
                        onChange={(e)=>handleSiteStatus(e, props.row.original.SiteID, props.row.original.IsActive)}
                        rightLabel={""}
                        value={props.row.original['IsActive']}
                        step={1}
                        min={0}
                        max={1}
                    />
                )
            }
        },
        {
            Header: 'Default',
            accessor: 'IsDefault',
            Cell: (props) => {
                return (
                    <SlideInput
                        leftLabel={""}
                        name={"IsDefault"}
                        onChange={(e)=>handleDefaultSite(e, props.row.original.SiteID, props.row.original.IsDefault)}
                        rightLabel={""}
                        value={props.row.original['IsDefault']}
                        step={1}
                        min={0}
                        max={1}
                    />
                )
            }
        },
        {
            Header: 'Edit',
            accessor: 'Edit',
            Cell: (cell) => {
                return (<div onClick={() => navigate(`/systemAdmin/editSite/${cell.row.original?.SiteID}`)}>
                        <SVGraphics className={'w-6 h-6 cursor-pointer'} svgname={'edit'}/>

                    </div>
                )
            }
        },
    ]

    const filterOnChange = async (e, type) => {
        setIsLoading(true)
        let inputs = [...searchInputs]
        let filters = {...siteFilter}
        let statusFilter = filters.filters.findIndex(x => x.name === type)
        if (statusFilter > -1) {
            if (!e.target.value) {
                filters['filters'].splice(statusFilter, 1)
            } else {

                filters['filters'][statusFilter]['value'] = e.target.value.value
            }
        } else {
            filters.filters.push({name: type, value: e.target.value.value})
        }
        if (type === 'Country') {
            let index = inputs.findIndex(x => x.name === 'State')
            if (e && e.target.value) {
                let states = countryStates.find(x => x.Country === e.target.value.value)
                if (states) {
                    let data = [...new Set(states.state)]
                    let statesData = []
                    data.forEach(d => {
                        statesData.push({value: d, label: d})
                    })
                    if (index > -1) {
                        inputs[index]['data'] = statesData
                    }

                }
            } else {
                if (index > -1) {
                    inputs[index]['data'] = []
                }
            }
            setSearchInputs(inputs)
        }

        setSiteFilter(filters)
        fetchData().then(p => {
            setData(p)
        })

    }

    const clearFilters = async () => {
        setIsLoading(true)
        searchInputs[2]['data'] = []
        setSiteFilter({filters: []})
        fetchData().then(p => {
            setData(p)
        })

    }

    return (
        <div className={Constants.mainScreenClass}>
            <div className={"site-search-inputs"}>
                {searchInputs.map(item => {
                    return <SelectAlphaIcon
                        key={item.name}
                        svgName={item.svgName}
                        data={item.data}
                        name={item.name}
                        label={item.label}
                        value={siteFilter['filters'].find(filter => filter.name === item.name)?.value}
                        selected={siteFilter['filters'].find(filter => filter.name === item.name)?.value}
                        type={item.type}
                        disabled={item.disabled}
                        onChange={(e) => filterOnChange(e, item.name)}
                        hasLine={item.hasLine}
                        inputClassName={'Select-Icon'}
                    />
                })}
                <div
                    className={'ml-[3rem] lg:ml-4 text-alphaTauButton text-[1.25rem] h-[3.5rem] font-bold font-Roboto flex items-center cursor-pointer'}
                    onClick={() => clearFilters()}><span className={'mr-4'}>&#10005;</span> Clear all
                </div>
            </div>

            <div className={"w-width90 lg:w-[98%]"}>
                <SearchInputButtonComponent
                    buttonText={"+ Add new site"}
                    hasButton={true}
                    inputClass={""}
                    addNewOnClick={() => navigate('/systemAdmin/addSite')}
                    onChange={(e) => handleSearch(e)}/>
                <Table data={filteredData} columns={columns} isLoading={isLoading}/>
            </div>

            {/*<Alert*/}
            {/*bg={'bg-[#FAF2F2]'}*/}
            {/*border={'border-[#FF3434]'}*/}
            {/*color={'text-[#F00]'}*/}
            {/*message={'Site can not be disabled due to measurements in progress '}*/}
            {/*icon={'alert'}*/}
            {/*/>*/}


        </div>
    )
}