import React, { useEffect, useState } from "react";
import { SelectAlpha } from "../../input/SelectAlpha";
import { SelectAlphaIcon } from "../../input/SelectAlphaIcon";
import { TimeInputAlpha } from "../../input/TimeInputAlpha";
import { FooterSaveButtons } from "../../input/FooterSaveButtons";
import SVGraphics from "../../../assets/SVGraphics";
import { LoadingComponent } from "../../input/LoadingComponent";
import { OrdersApi } from "../../../services/ApiService";
import moment from "moment";
import { AlertConfirm } from "../../modals/Confirm";
import { date, object } from "yup";
import { Error } from "../../forms/Error";
import ChambersApi from "../../../services/ApiServices/ChambersApi";

export function EditOrderPlan(props) {
    const [charge,] = useState(props.charge)
    console.log(charge.chargeId)
    // const [entry, setEntry] = useState(null);
    const [initialOrder, setInitialOrder] = useState({})
    const [initialCharge, setInitialCharge] = useState({})
    // const [startTime, setStartTime] = useState({});
    // const [endTime, setEndTime] = useState({});
    const [orders, setOrders] = useState([]);
    const [error, setError] = useState('');
    const [isError, setIsError] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [startTimeDisable, setStartTimeDisable] = useState(false)
    const [endTimeDisable, setEndTimeDisable] = useState(false)
    const [expectedActivity, setExpectedActivity] = useState('')
    const [fixEndDate, setFixEndDate] = useState(false)
    const [fixStartDate, setFixStartDate] = useState(false)
    const [calculated, setCalculated] = useState(false)
    const [chambers, setChambers] = useState()
    const [selectedChamber, setSelectedChamber] = useState({
        label: props.order?.ChamberID,
        value: props.order?.chamberInternalID
    })
    const orderSchema = object().shape({
        startTimeDate: date().required('Please insert start date').test('checkEndDate', 'Start date must be before end date', function (value) {
            const { parent, createError } = this;
            if ((parent.endTimeDate)?.getTime() <= value.getTime()) {
                return createError({
                    path: 'startTimeDate',
                    message: 'Start date must be before end date',
                });
            }
            return true;
        }),
        endTimeDate: date().required('Please insert start date').test('checkEndDate', 'End date must be after start date', function (value) {
            const { parent, createError } = this;
            if ((parent.startTimeDate)?.getTime() >= value.getTime()) {
                return createError({
                    path: 'endTimeDate',
                    message: 'End date must be after start date ',
                });
            }
            return true;
        }),
    });
    const getChambers = async () => {
        let data = await ChambersApi.getAllChambers({ filters: [{ name: 'SiteID', value: props.siteId }] })
        if (data && data.data) {
            let chNames = [];
            data.data.forEach(element => {
                chNames.push({
                    label: element.ChamberID,
                    value: element.chamberInternalID
                })
            });
            setChambers(chNames)
        }
    }
    useEffect(() => {
        setInitialOrder(props.order)
        setInitialCharge(JSON.parse(JSON.stringify(charge)))
        // setStartTime(getTime(charge.startTime))
        // setEndTime(getTime(charge.endTime))
        setExpectedActivity(props.order.expectedActivityAtProcedure)
        getData().then(data => {
            setOrders(data)
        })
        // setEntry(props.order)
        getChambers();
    }, [props.order])

    const getData = async () => {
        let _orders = []
        let ordersNames = await OrdersApi.getRelevantOrders(props.siteId)
        if (ordersNames && ordersNames.status === 200) {
            ordersNames = ordersNames.data
            ordersNames.forEach(order => {
                _orders.push({
                    label: order.ORDNAME,
                    value: order.OrderID
                })
            })
            return _orders
        }
    }

    /**
     * cancel all the changes and return the data to the initial states
     */
    const resetData = () => {
        //setEntry(initialOrder)
        // setStartTime(getTime(initialCharge.startTime))
        // setEndTime(getTime(initialCharge.endTime))
        for (let key in initialCharge) {
            if (initialCharge.hasOwnProperty(key)) {
                if (key === 'startDate' || key === 'endDate') {
                    charge[key] = new Date(initialCharge[key]);
                } else {
                    charge[key] = initialCharge[key];
                }
            }
        }

        setExpectedActivity(props.order.expectedActivityAtProcedure)
        setStartTimeDisable(false)
        setEndTimeDisable(false)
        setFixEndDate(false)
        setFixStartDate(false)
        setIsError(false)
        setDisabled(true)
        setError('')
        setCalculated(false)
    }

    const getTime = (time) => {
        time = time.trim().split(" ");
        let [hour, minute] = time[0].split(":");
        return {
            hour,
            minute,
            hourType: time[1]
        };
    };


    const dates = ["startDate", "endDate"]
    const titleClass = 'text-[#444] text-[1.25rem] font-bold'
    const inputClass = "select-repeat w-[21.75rem]  rounded-xl h-14  font-Roboto text-alphaTauText "

    /**
     * calculate the expected activity for the order
     * @param plan
     * @returns {Promise<undefined|AxiosResponse<any>|*>}
     */
    const calculateActivity = async () => {
        let body = {
            chamberInternalID: charge.chamberInternalID,
            startDate: new Date(charge.startDate).toISOString(),
            endDate: new Date(charge.endDate).toISOString()
        }
        return await OrdersApi.calcExpectedActivity(props.order.OrderID, body)
    }

    const reformatDateStringToDate = (val) => {
        const [day, monthStr, year, time, period] = val.split(/[\s,]+/);

        const monthMap = {
            Jan: "01", Feb: "02", Mar: "03", Apr: "04",
            May: "05", Jun: "06", Jul: "07", Aug: "08",
            Sep: "09", Oct: "10", Nov: "11", Dec: "12"
        };

        // Check if monthStr is a number; if not, map it using monthMap
        const month = isNaN(monthStr) ? monthMap[monthStr] : monthStr.padStart(2, "0");

        // Split the time into hours and minutes
        let [hours, minutes] = time.split(":").map(Number);

        // Adjust hours for 12-hour format
        if (period === "PM" && hours < 12) hours += 12;
        if (period === "AM" && hours === 12) hours = 0;

        // Create an ISO-compliant date string
        const isoString = `${year}-${month}-${day}T${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:00`;
        const date = new Date(isoString);
        return date
    }
    const onEntryChange = async (event) => {
        //let _order = { ...entry }
        let name = event.target.name
        let value = event.target.value
        if (name === 'OrderID') {
            setStartTimeDisable(true)
            setEndTimeDisable(true)
            props.order[name] = value.value
            setDisabled(false)
            let calcActivity = await calculateActivity(props.order)
            if (calcActivity && calcActivity.status === 200) {
                calcActivity = calcActivity.data
                if (calcActivity?.deviationFlag) {
                    await openModal(calcActivity.deviationOfExpecedActivity)
                    // await resetData()
                }
                let expected = calcActivity?.asymptoticActivityInTreatment
                props.order['expectedActivityAtProcedure'] = expected
                setExpectedActivity(expected)
            }
        } else if (name === 'fixEndDate' || name === 'fixStartDate') {
            if (name === 'fixEndDate') {
                charge['endDate'] = initialCharge.endDate
                setFixEndDate(value)
                setEndTimeDisable(true)
            } else {
                charge['startDate'] = initialCharge.startDate
                setFixStartDate(value)
                setStartTimeDisable(true)
            }
        } else if (name === 'startDate' || name === 'endDate') {
            let date = value
            let startTime = date.getTime();
            let endTime = date.getTime();
            if (name === 'startDate') {
                setEndTimeDisable(true)
            } else {
                setStartTimeDisable(true)
            }
            let selectedDate = name === 'startDate' ? startTime : endTime
            // let newDate = new Date(date.setHours(selectedDate.hourType === 'PM' ? Number(selectedDate.hour) + 12 : Number(selectedDate.hour), Number(selectedDate.minute)))
            charge[name] = new Date(selectedDate);//.format('DD MMM YYYY h:mm A')
            // charge[name] = moment(newDate).format('YYYY-MM-DD')
            // if (!fixStartDate && !fixEndDate) {
            //     let getDate = await OrdersApi.getFixDate(props.orderId, { // WHAT IS THIS FIX DATE ???????????????????????????????????????????????????????????????????????????????????????????????????????????????????

            //         startDate: charge['startDate'],//reformatDateStringToDate(props.order['startTimeDate']),
            //         endDate: charge['endDate'],//reformatDateStringToDate(props.order['endTimeDate']),
            //         changed: name,
            //         chamberInternalID: props.order.chamberInternalID
            //     })
            //     if (getDate?.data.NewDate) {
            //         let returnedDate = getDate?.data?.NewDate
            //         charge[name === 'startDate' ? 'endDate' : 'startDate'] = moment(returnedDate).format('DD MMM YYYY, h:mm A')
            //     }
            // }
            validateOrder(props.order, name === 'startDate' ? 'startTimeDate' : 'endTimeDate') // WHAT IS THIS VALIDATION SHOULD DO ???????????????????????????????????????????????????????????????????????????????????????????????????????????????????
            console.log('Changing date')
        }
        else if (name === 'hour' || name === 'minute' || name === 'hourType') {
            let type = event.target.type;
            let date = charge[String(type + 'Date')];

            switch (name) {
                case "hour": {
                    let hour = parseInt(event.target.value, 10);
                    if (date.getHours() >= 12) {
                        // Preserve PM time if currently in PM
                        hour = hour % 12 + 12;
                    } else {
                        // Preserve AM time if currently in AM
                        hour = hour % 12;
                    }
                    date.setHours(hour);
                    break;
                }
                case "minute":
                    date.setMinutes(parseInt(event.target.value, 10));
                    break;
                case "hourType": {
                    let currentHour = date.getHours();
                    if (event.target.value === "PM" && currentHour < 12) {
                        date.setHours(currentHour + 12);
                    } else if (event.target.value === "AM" && currentHour >= 12) {
                        date.setHours(currentHour - 12);
                    }
                    break;
                }
                default:
                    break;
            }
        }
        // } else {
        //     _order[name] = value
        // }
        // setEntry(_order)
    }

    const validateOrder = (order, name) => {
        orderSchema.validate(order, { abortEarly: false }).then(function (value) {
            setIsError(false)
            if (calculated) {
                setDisabled(false)
            }
        })
            .catch(function (err) {
                err.inner?.forEach(error => {
                    if (name === error.path) {
                        setError(error.errors[0])
                        setDisabled(true)
                        setIsError(true)
                    }
                })
            });
    }

    const setTimeToDate = (date, time) => {
        let newDate = new Date(date.setHours(time.hourType === 'PM' ? Number(time.hour) + 12 : Number(time.hour), Number(time.minute)))
        return (newDate)
    }

    /**
     * display alert if the new calculated activity is not as expected
     * @returns {Promise<void>}
     */
    const openModal = async (deviation) => {
        let alert = await AlertConfirm({
            proceedLabel: 'Yes',
            cancelLabel: 'No',
            options: { hasSVG: true, svgName: "red-warning-circle" }
        }, `Please note, there is a deviation of ${deviation} from the required activity would you like to proceed?`, 'Unexpected Activity',)

        if (!alert) {
            await resetData()
        }
    }

    /**
     * return the new calculated activity and set the activity
     * @returns {Promise<void>}
     */
    const calculateOrderActivity = async () => {
        setCalculated(true)
        //let _order = { ...entry }
        let calc = await calculateActivity()
        if (calc && calc.status === 200) {
            calc = calc.data
            // else{
            let expected = calc?.asymptoticActivityInTreatment
            props.order['expectedActivityAtProcedure'] = expected
            setExpectedActivity(expected)
            //setEntry(_order)
            // }
            setDisabled(false)
            validateOrder(charge, 'startTime')
            validateOrder(charge, 'endTime')
            if (calc.deviationFlag) {

                await openModal(calc.deviationOfExpecedActivity)

                // await resetData()
            }
        }
    }

    const savePlan = async () => {
        //let startDate = moment(new Date(charge.startDate), "DD MM YYYY, h:mm A").format('YYYY-MM-DDTHH:mm:ss')
        //let endDate = moment(new Date(charge.endDate), "DD MM YYYY, h:mm A").format('YYYY-MM-DDTHH:mm:ss')
        let startDate = new Date(charge.startDate).toISOString().slice(0, 19);
        let endDate = new Date(charge.endDate).toISOString().slice(0, 19);

        let updatedPlan = {
            "orderName": props.order.ORDNAME,
            "orderID": props.order.OrderID,
            "startDate": startDate,
            "endDate": endDate,
            "expectedActivity": props.order.expectedActivityAtProcedure,
            "siteID": props.siteId,
            "ChamberID": selectedChamber.label,
            "chamberInternalID": selectedChamber.value
        }

        let editPlan = await OrdersApi.editSubPlans(updatedPlan, charge.Index)
        if (editPlan && editPlan.data) {
            editPlan = editPlan.data
            if (editPlan.error) {
                let alert = await AlertConfirm({
                    proceedLabel: 'Ok',
                    cancelLabel: 'none',
                    options: { hasSVG: true, svgName: "red-warning-circle" }
                }, editPlan.error, 'Unavailable Chamber',)
                if (alert) {
                    await resetData()
                }
            } else {
                props.planSaved()
            }
        }
    }

    const handleChangeRaw = async (event, type) => {
        const newRaw = new Date(moment(event.currentTarget.value, "DD/MM/YYYY"));
        if (newRaw instanceof Date && !isNaN(newRaw)) {
            let e = {
                target: {
                    name: type,
                    value: newRaw
                }
            }
            await onEntryChange(e)
        }
    }
    const handleChamberChanged = (chamber) => {
        console.log(chamber)
        setSelectedChamber(chamber)
        setDisabled(props.order?.chamberInternalID === chamber.value)
    }
    const handleCancel = () => {
        for (let key in initialCharge) {
            if (initialCharge.hasOwnProperty(key)) {
                if (key === 'startDate' || key === 'endDate') {
                    charge[key] = new Date(initialCharge[key]);
                } else {
                    charge[key] = initialCharge[key];
                }
            }
        }

        props.cancelEdit();
    }

    const getChargeTime = (type) => {//'4:24 PM'
        let hour = 0;
        let minute = 0;
        let hourType = "AM";
        let timeString = "";
        switch (type) {
            case 'startDate':
                timeString = charge.startTime;
                hour = timeString.split(':')[0]
                minute = timeString.split(':')[1].split(" ")[0]
                hourType = timeString.split(':')[1].split(" ")[1]
                break;
            case 'endDate':
                timeString = charge.endTime;
                hour = timeString.split(':')[0]
                minute = timeString.split(':')[1].split(" ")[0]
                hourType = timeString.split(':')[1].split(" ")[1]
                break;
            default:
                break;
        }
        console.log(type)
        return {
            hour,
            minute,
            hourType
        }
    }

    return (
        <div id="modalEl" tabIndex="-1" aria-hidden="true" className="fixed top-0  left-0 right-0 font-Roboto w-auto z-50 py-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal lg:h-modal md:h-full bg-stone-900/60 flex items-center justify-center ">

            <div className="relative bg-gray-300 w-auto rounded-lg md:top-24 lg:top-0 shadow border p-4">
                {props.order ?
                    <div className="h-[51.375rem] w-[44.375rem] md:mt-8 lg:mt-0">
                        <div
                            className={"rounded-full bg-[#F6F7F8] w-8 h-8 p-1 text-center float-right cursor-pointer right-[2%] top-[2%] relative text-black font-semibold"}
                            onClick={() => handleCancel()}>
                            &#10005;
                        </div>
                        <div className={'w-[100%] h-[100%] pl-[40px] pr-[2rem] pt-[30px] '}>
                            <div
                                className={'text-alphaTauButton font-bold w-[100%] text-center text-[27px] mb-[20px]'}>Edit
                                Charge
                            </div>
                            <div className={'flex border-b border-b-[#D7DDDF] w-[100%] h-auto pb-4'}>
                                <div>
                                    <div className={titleClass}>Order name</div>
                                    <SelectAlpha
                                        labelClassName={"font-Roboto text-inputSize text-alphaTauText font-bold mb-2"}
                                        inputClassName={inputClass}
                                        inputBg={'#F2F5FA'}
                                        containerClassName={"mb-6 mr-12"}
                                        name={'OrderID'}
                                        onChange={val => onEntryChange({ target: { name: 'OrderID', value: val } })}
                                        data={orders}
                                        isIcon={true}
                                        svgName={'total-small'}
                                        value={props.order['OrderID']}
                                        selected={props.order['OrderID']}
                                    />
                                </div>
                                <div className={' h-[2rem] items-center'}>
                                    <div className="flex">
                                        <div className={titleClass}>Chamber ID:</div>
                                        <div className={'text-[1.125rem] text-[#000] ml-[0.875rem]'}>{props.order?.ChamberID} </div>
                                    </div>

                                    <SelectAlpha
                                        labelClassName={"font-Roboto text-inputSize text-alphaTauText font-bold mb-2"}
                                        inputClassName={inputClass}
                                        inputBg={'#F2F5FA'}
                                        containerClassName={"mb-6 mr-12"}
                                        name={'ChamberID'}
                                        onChange={val => handleChamberChanged(val)}
                                        data={chambers && chambers}
                                        isIcon={true}
                                        svgName={'total-small'}
                                        value={selectedChamber.value}
                                        selected={selectedChamber.label}
                                    />
                                </div>
                            </div>
                            <div className={' border-b border-b-[#D7DDDF] w-[100%] h-auto pb-4 '}>
                                <button className={'w-full flex justify-end items-center relative top-[26px]'}
                                    onClick={() => resetData()}
                                >
                                    <SVGraphics className="mr-1 h-6 w-6 ml-4" svgname={'reset'} />
                                    <div className={'text-[#747879] text-[11px] '}>Reset all</div>
                                </button>

                                {dates.map(date => {
                                    return <div key={date} className={' w-[100%] h-auto pb-2 '}>
                                        <div
                                            className={titleClass}>{`${date === 'startDate' ? 'Start' : 'End'} Time`} </div>
                                        <div>
                                            <div className={' items-center pt-2'}>
                                                <div className={'flex items-center'}>
                                                    <SelectAlphaIcon
                                                        class={' w-[21.75rem] '}
                                                        disabled={date === 'startDate' ? startTimeDisable : endTimeDisable}
                                                        mb={'mb-2'}
                                                        inputWidth={'21.75rem'}
                                                        isClearable={'false'}
                                                        backgroundColor={'#F2F5FA'}
                                                        svgName={'calendar'}
                                                        data={null}
                                                        isRange={false}
                                                        type={'date'}

                                                        minDate={new Date()}
                                                        selected={charge ? new Date(charge[date]) : null}
                                                        name={date}
                                                        label={'Select a day'}
                                                        onChangeDate={val => onEntryChange({
                                                            target: {
                                                                name: date,
                                                                value: val
                                                            }
                                                        })}
                                                        onChangeRaw={(e) => handleChangeRaw(e, date)}

                                                        hasLine={true}
                                                        inputClassName={'Select-Icon'}

                                                    />

                                                    <div
                                                        className={'h-full flex items-center justify-center text-[#252B42] font-Roboto text-[1.125rem] ml-[3.281rem]'}>
                                                        <input
                                                            className={'w-[1.563rem] h-[1.563rem] rounded-[0.25rem] border border-[#F2F5FA]'}
                                                            checked={date === 'startDate' ? fixEndDate : fixStartDate}
                                                            disabled={date === 'startDate' ? startTimeDisable : endTimeDisable}
                                                            type={'checkbox'}
                                                            onClick={(e) => onEntryChange({
                                                                target: {
                                                                    name: date === 'startDate' ? 'fixEndDate' : 'fixStartDate',
                                                                    value: e.target.checked
                                                                }
                                                            })}
                                                        />
                                                        <div
                                                            className={'text-[1.125rem] text-[#444] ml-[0.875rem]'}>{`Fix ${date === 'startDate' ? 'end' : 'start'}  date`}</div>

                                                        <SVGraphics className="mr-3 h-6 w-6 ml-4" svgname={'pin'} />
                                                    </div>
                                                </div>

                                                <TimeInputAlpha
                                                    containerClassName={'flex'}
                                                    selectedHour={getChargeTime(date).hour}// === 'startDate' ? startTime['hour'] : endTime['hour']}
                                                    selsctedMinute={getChargeTime(date).minute}
                                                    hourName={'hour'}
                                                    disabled={date === 'startDate' ? startTimeDisable : endTimeDisable}
                                                    minuteName={'minute'}
                                                    onChangeHours={e => onEntryChange({
                                                        target: {
                                                            name: 'hour',
                                                            type: date === 'startDate' ? 'start' : 'end',
                                                            value: e.target.value
                                                        }
                                                    })}
                                                    onChangeMinutes={e => onEntryChange({
                                                        target: {
                                                            name: 'minute',
                                                            type: date === 'startDate' ? 'start' : 'end',
                                                            value: e.target.value
                                                        }
                                                    })}
                                                    selectedHourType={getChargeTime(date).hourType}
                                                    onChangeHourType={(e) => onEntryChange({
                                                        target: {
                                                            name: 'hourType',
                                                            type: date === 'startDate' ? 'start' : 'end',
                                                            value: e.target.value
                                                        }
                                                    })}
                                                />

                                            </div>

                                        </div>
                                    </div>
                                })}
                                <div className={`flex  w-[100%] ${isError ? 'justify-between' : 'justify-end'}`}>
                                    {
                                        isError &&
                                        <Error
                                            isShown={isError}
                                            message={error}
                                        />
                                    }

                                    <button
                                        onClick={() => calculateOrderActivity()}
                                        className={'w-[14.313rem] h-[3.5rem] rounded-[10px] border border-alphaTauButton font-bold text-alphaTauButton font-Roboto mt-[0.5rem] hover:bg-alphaTauButton hover:text-white'}>Calculate
                                    </button>
                                </div>
                            </div>
                            <div className={'flex items-center mt-[0.625rem]'}>
                                <div className={titleClass}>Expected activity:</div>
                                <div
                                    className={'text-[1.125rem] text-[#000] ml-[0.875rem]'}>{`${expectedActivity} kBq`}</div>
                            </div>
                            <div className={'w-[100%] flex justify-center pb-2'}>
                                <FooterSaveButtons
                                    containerClass={'my-[-10px]'}
                                    disabledSave={false}
                                    onClickSave={() => savePlan()}
                                    saveLabel={'Save'}
                                    saveButtonClass={'mb-4 h-[73px]'}
                                    buttonsClass={'flex-col justify-center items-center'}
                                    onClickCancel={() => handleCancel()}
                                    cencelLabel={'Cancel'}

                                />
                            </div>
                        </div>

                    </div> :
                    <LoadingComponent />
                }
            </div>
        </div>

    )
}